// Company
export const company_name_key = 'company.name';

// Pages
export const home_page_key = 'pages.home.name';
export const about_page_key = 'pages.about.name';
export const contact_page_key = 'pages.contact.name';
export const coding_page_key = 'pages.coding.name';
export const photography_page_key = 'pages.photography.name';
export const gaming_page_key = 'pages.gaming.name';
export const cv_page_key = 'pages.cv.name';
export const health_page_key = 'pages.health.name';

// Header
export const header_title_key = 'header.title';

// Footer
export const footer_copyright_key = 'footer.copyright';

// Home Page
export const home_page_title_key = 'pages.home.title';

//About Page
export const about_page_title_key = 'pages.about.title';

// Error Page
export const error_message_title_key = 'pages.error.title';
export const error_404_message_key = 'pages.error.message';
export const go_back_key = 'pages.error.goBack';

// Contact Page
export const contact_page_title_key = 'pages.contact.title';
